@mixin add-font($name, $path) {
	@font-face {
		font-family: quote($name);
		src: url('#{$path}/#{$name}.eot');
		src: url('#{$path}/#{$name}.eot?#iefix') format('embedded-opentype'), url('#{$path}/#{$name}.woff2') format('woff2'),
			url('#{$path}/#{$name}.woff') format('woff');
	}
}

@include add-font('ScandiaWebBold', './fonts/Scandia/ScandiaWebBold');
@include add-font('ScandiaWebBoldItalic', './fonts/Scandia/ScandiaWebBoldItalic');
@include add-font('ScandiaWebLight', './fonts/Scandia/ScandiaWebLight');
@include add-font('ScandiaWebLightItalic', './fonts/Scandia/ScandiaWebLightItalic');
@include add-font('ScandiaWebMedium', './fonts/Scandia/ScandiaWebMedium');
@include add-font('ScandiaWebMediumItalic', './fonts/Scandia/ScandiaWebMediumItalic');
@include add-font('ScandiaWebRegular', './fonts/Scandia/ScandiaWebRegular');
@include add-font('ScandiaWebRegularItalic', './fonts/Scandia/ScandiaWebRegularItalic');
@include add-font('ScandiaWebStencil', './fonts/Scandia/ScandiaWebStencil');

@include add-font('MontserratWebRegular', './fonts/Montserrat/MontserratWebRegular');
@include add-font('MontserratWebBold', './fonts/Montserrat/MontserratWebBold');
